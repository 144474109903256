@import "abstracts/variables";

.p-slider {
  border-radius: $border-radius;

  &.p-slider-vertical {
    width: 0.286em;
    height: 100%;
    background-color: $dark-4;

    .p-slider-handle {
      margin-left: -0.34em;
      margin-bottom: -0.40em;
    }
  }


  &.p-slider-horizontal {
    height: 0.286em;
    background: $dark-4;

    .p-slider-handle {
      margin-top: -0.47rem;
      margin-left: -0.5715rem;
      background: $light-shade;
      z-index: 3;
    }

    .p-slider-handle {
      height: 0.9em;
      width: 0.9em;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
    }

    .p-slider-range {
      transition: width 0.2s;
      background: $primary-color;
      z-index: 2;
    }

  }

  .p-slider-range {
    background: $primary-color;
    border-radius: $border-radius;
  }

  .p-slider-handle {
    height: 0.7em;
    width: 0.7em;
    background: $light-shade;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

  &:not(.p-disabled) {
    .p-slider-handle {
      &:hover {
        background: $primary-color;
      }

      &:focus {
        background: $primary-color;
      }
    }
  }
}
