@import "abstracts/variables";

:root {
  --sg-primary-color: #224D58;
  --sg-primary-light-color: #224D58B3;
  --link-color-50: hsla(var(--sg-primary-color), .5);
}

html {
  font-size: 1vw;
  //font-size: 1vmin;

  body {
    background: $dark-1;
    height: 100vh;
    overscroll-behavior-x: none;
    font-size: $base-font-size;
    font-family: $base-font-family;

    /*::-webkit-scrollbar-track {
      border-radius: $border-radius;
      background-color: $shade-3;
    }

    ::-webkit-scrollbar {
      width: 12px;
      background-color: $shade-3;
      border-radius: $border-radius;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $dark-4;
    }*/
  }

  * {
    &:focus {
      outline: none !important;
    }
  }

  svg {
    width: 1.3em;
    height: 1.3em;
  }
}
