@import "functions";

$base-font-family: ‘Inter’, sans-serif;

$base-font-size: 1em;

$h1-font-size: 48px; // 48
$h2-font-size: 32px; // 32
$h3-font-size: 20px; // 20
$h4-font-size: 16px; // 16
$h5-font-size: 14px; // 14
$h6-font-size: 12px; // 12

