$light-shade: #ffffff;

$shade-1: #F5F5F5;
$shade-2: #F0F0F0;
$shade-3: #EBEBEB;
$shade-4: #bdbdbd;

$dark-1: #1A1C23;
$dark-2: #2D2F34;
$dark-3: #3D4046;
$dark-4: #4D5158;

$adme-pink: #FF227A;
$green: #22AD1F;

$primary-color: var(--sg-primary-color);
$primary-color-light: var(--sg-primary-light-color);

//todo: remove
$primary-color1: var(--link-color-50);


$black: #000;
$white: #fff;

$white-80: rgba($white, 0.8);
$white-40: rgba($white, 0.4);
