//@import "~primeicons/primeicons.css";
//@import "~primeng/resources/themes/saga-blue/theme.css";
//@import "~primeicons/primeicons.css";
//@import "~primeng/resources/primeng.css";
//@import "~primeng/resources/themes/saga-blue/theme.css";
//@import "~primeng/resources/components/slider/slider.css";

@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.css";

