@import "variables";

@font-face {
  font-family: '#{$skillground-font-family}';
  src:
    url('#{$skillground-font-path}/#{$skillground-font-family}.ttf?gzrawj') format('truetype'),
    url('#{$skillground-font-path}/#{$skillground-font-family}.woff?gzrawj') format('woff'),
    url('#{$skillground-font-path}/#{$skillground-font-family}.svg?gzrawj##{$skillground-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$skillground-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info {
  &:before {
    content: $icon-info;
    color: #fff;
  }
}
.icon-loading {
  &:before {
    content: $icon-loading;
    color: #fff;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
    color: #fff;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
    color: #fff;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
    color: #fff;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
    color: #fff;
  }
}
.icon-bolt {
  &:before {
    content: $icon-bolt;
    color: #fff;
  }
}
.icon-caution {
  &:before {
    content: $icon-caution;
    color: #efa715;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
    color: #fff;
  }
}
.icon-clapper {
  &:before {
    content: $icon-clapper;
    color: #fff;
  }
}
.icon-color {
  &:before {
    content: $icon-color;
    color: #fff;
  }
}
.icon-course {
  &:before {
    content: $icon-course;
    color: #fff;
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;
    color: #fff;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
    color: #fff;
  }
}
.icon-drag {
  &:before {
    content: $icon-drag;
    color: #fff;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
    color: #fff;
  }
}
.icon-explainer {
  &:before {
    content: $icon-explainer;
    color: #fff;
  }
}
.icon-film {
  &:before {
    content: $icon-film;
    color: #fff;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
    color: #fff;
  }
}
.icon-full-screen {
  &:before {
    content: $icon-full-screen;
    color: #fff;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
    color: #fff;
  }
}
.icon-locked {
  &:before {
    content: $icon-locked;
    color: #fff;
  }
}
.icon-more {
  &:before {
    content: $icon-more;
    color: #fff;
  }
}
.icon-music {
  &:before {
    content: $icon-music;
    color: #fff;
  }
}
.icon-mute {
  &:before {
    content: $icon-mute;
    color: #fff;
  }
}
.icon-narration {
  &:before {
    content: $icon-narration;
    color: #fff;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
    color: #fff;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
    color: #fff;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
    color: #fff;
  }
}
.icon-projects {
  &:before {
    content: $icon-projects;
    color: #fff;
  }
}
.icon-redo {
  &:before {
    content: $icon-redo;
    color: #fff;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
    color: #fff;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
    color: #fff;
  }
}
.icon-small-cross {
  &:before {
    content: $icon-small-cross;
    color: #fff;
  }
}
.icon-subtitles {
  &:before {
    content: $icon-subtitles;
    color: #fff;
  }
}
.icon-tag {
  &:before {
    content: $icon-tag;
    color: #fff;
  }
}
.icon-tags {
  &:before {
    content: $icon-tags;
    color: #fff;
  }
}
.icon-text {
  &:before {
    content: $icon-text;
    color: #fff;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
    color: #fff;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;
    color: #fff;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
    color: #fff;
  }
}
.icon-video {
  &:before {
    content: $icon-video;
    color: #fff;
  }
}
.icon-volume {
  &:before {
    content: $icon-volume;
    color: #fff;
  }
}
.icon-wand {
  &:before {
    content: $icon-wand;
    color: #fff;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
    color: #f6332a;
  }
}
