@import "abstracts/variables";

.btn {
  &.btn-primary {
    height: 3em;
    padding: 0.7em 1.2em;
    background-color: $primary-color;
    border-color: $primary-color;
    font-size: $base-font-size;
    font-family: $base-font-family;
    box-shadow: none;
    border-radius: 0.5em;
    &:hover {
      background-color: $primary-color;
      filter: brightness(90%);
    }
  }
}
