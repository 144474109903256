$skillground-font-family: "SkillGround-Icon-Font" !default;
$skillground-font-path: "/assets/fonts" !default;

$icon-info: unquote('"\\e92c"');
$icon-loading: unquote('"\\e92b"');
$icon-arrow-down: unquote('"\\e900"');
$icon-arrow-left: unquote('"\\e901"');
$icon-arrow-right: unquote('"\\e902"');
$icon-arrow-up: unquote('"\\e903"');
$icon-bolt: unquote('"\\e904"');
$icon-caution: unquote('"\\e905"');
$icon-check: unquote('"\\e906"');
$icon-clapper: unquote('"\\e907"');
$icon-color: unquote('"\\e908"');
$icon-course: unquote('"\\e909"');
$icon-cross: unquote('"\\e90a"');
$icon-download: unquote('"\\e90b"');
$icon-drag: unquote('"\\e90c"');
$icon-edit: unquote('"\\e90d"');
$icon-explainer: unquote('"\\e90e"');
$icon-film: unquote('"\\e90f"');
$icon-flag: unquote('"\\e910"');
$icon-full-screen: unquote('"\\e911"');
$icon-home: unquote('"\\e912"');
$icon-locked: unquote('"\\e913"');
$icon-more: unquote('"\\e914"');
$icon-music: unquote('"\\e915"');
$icon-mute: unquote('"\\e916"');
$icon-narration: unquote('"\\e917"');
$icon-pause: unquote('"\\e918"');
$icon-play: unquote('"\\e919"');
$icon-plus: unquote('"\\e91a"');
$icon-projects: unquote('"\\e91b"');
$icon-redo: unquote('"\\e91c"');
$icon-search: unquote('"\\e91d"');
$icon-settings: unquote('"\\e91e"');
$icon-small-cross: unquote('"\\e91f"');
$icon-subtitles: unquote('"\\e920"');
$icon-tag: unquote('"\\e921"');
$icon-tags: unquote('"\\e922"');
$icon-text: unquote('"\\e923"');
$icon-trash: unquote('"\\e924"');
$icon-undo: unquote('"\\e925"');
$icon-user: unquote('"\\e926"');
$icon-video: unquote('"\\e927"');
$icon-volume: unquote('"\\e928"');
$icon-wand: unquote('"\\e929"');
$icon-warning: unquote('"\\e92a"');

