@import "video.js/dist/video-js.css";
@import "abstracts/variables";


.video-js .vjs-big-play-button {
  background-color: $primary-color;
}

.video-js {

  &.vjs-has-started:not(.vjs-playing) {
    .vjs-big-play-button {
      display: block;
    }
  }

  &.vjs-playing {
    &.vjs-user-inactive {
      .vjs-text-track-display {
        bottom: 0;
      }
    }
  }

  &.vjs-user-inactive {

  }

  .vjs-big-play-button {

    background-color: $primary-color;
    border:none;
    background-image: url('data:image/svg+xml;charset=utf-8,<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z" fill="transparent"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M29 42.6767C29 43.4514 29.8427 43.9319 30.5094 43.5373L43.5506 35.8184C44.2038 35.4318 44.2051 34.4871 43.553 34.0987L30.5117 26.3311C29.8452 25.934 29 26.4144 29 27.1902V42.6767Z" fill="white"/> </svg> ');
    background-repeat: no-repeat;
    background-size: contain;
    transition: all 100ms ease-in-out !important;
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;

    &:hover {
      transform: scale(1.1);
    }

    .vjs-icon-placeholder {
      display: none;
    }

    .vjs-time-tooltip {
      display: none !important;
    }

  }

  &:hover .vjs-big-play-button {
    background-color: $primary-color;
  }

  .vjs-text-track-display {
    bottom: 5%;
    transition: bottom 400ms ease-in-out;

    .vjs-text-track-cue {
      inset: unset !important;
      bottom: 0 !important;
      height: auto !important;
      > div {
        font-family: $base-font-family !important;
        font-size: 0.625em !important;
        padding: 0.625em !important;
        border-radius: 0.313em !important;
        opacity: 0.8 !important;
        display: inline-block !important;
        background-color: $dark-3 !important;
      }
    }
  }
}
