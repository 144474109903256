@import "abstracts/variables";

.p-dialog {
  border-radius: $border-radius;

  &.sg-blue {
    .p-dialog-content {
      border-radius: $border-radius;
      color: $light-shade;
      padding: 20px !important;
    }
  }

  &.overflow {
    height: 100%;
  }

  &.header-text-center {
    .p-dialog-header {
      justify-content: center;
    }
  }

  &.divide-header {
    .p-dialog-header {
      border-bottom: 1px solid $shade-2;
    }
  }

  &.content-no-padding {
    .p-dialog-content {
      padding: 0 !important;
    }
  }

  .p-dialog-header {
    //background: $neutral-900;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    padding: 20px !important;
  }

  .p-dialog-content {
    //background: $neutral-900;
    border-radius: $border-radius;
    //border-bottom-right-radius: $border-radius;
    padding: 20px !important;
  }
}
